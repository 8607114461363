.AboutPage {
  text-align: center;
}

.header-info {
    flex: 1;
    margin-left: 20px;
}

.contacts {
    padding-top: 12%;
}

.description {

}

@media (max-width: 540px) {
    header {
        flex-wrap: wrap;
    }

    .photo {
        order: 1;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contacts {
        order: 2;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
    }

    .description {
        order: 4;
        flex-basis: 100%;
    }

    .header-info {
        order: 3;
        margin: 0; /* убираем отступ слева */
        flex-basis: 100%;
    }

    .header-info h1 {
        font-size: 36px;
        font-weight: bold;
    }

    .header-info p {
        margin-bottom: 5px;
    }

    .contacts {
        padding-top: 5%;
        padding-bottom: 5%;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Arial, sans-serif;
    background-color: #fff;
    color: #000;
    font-size: 16px;
    line-height: 1.5;
}

.container {
    margin: 0 auto;
    max-width: 800px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.hr-sections {
    border: none;
    border-top: 3px solid #000;
    margin: 30px 0;
}

.hr-experience {
    border: none;
    border-top: 1px solid #000;
    margin: 25px 0;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.photo img {
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.header-info h1 {
    font-size: 36px;
    font-weight: bold;
}

.header-info p {
    margin-bottom: 5px;
}

.sub-info {
    font-size: 16px;
    font-weight: bold;
}

section {
    margin-bottom: 40px;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

ul {
    list-style-type: none;
    margin-bottom: 10px;
}

ul li {
    margin-bottom: 5px;
}

ul li h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

ul li p {
    font-size: 16px;
    margin-bottom: 5px;
}

