body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.example-map p,
.jumbotron p {
  margin: 0;
  padding: 0;
}

.example-map {
  width: 100%;
  height: 60vh;
  margin-bottom: 1em;
}

.no-interaction {
  user-select: none;
  pointer-events: none;
}

.example-overlay {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  user-select: none;
  pointer-events: none;
  position: absolute;
  width: max-content;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
}

.example-control {
  top: 2.5em;
  right: 0.5em;
}

.example-list {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  font-size: 0.7em;
  list-style: lower-latin;
  padding: 0;
  overflow: hidden;
}

.example-list ul {
  padding: 0;
  overflow: hidden;
}

.example-list li {
  font-style: italic;
  overflow: hidden;
}

.example-overview .ol-overviewmap-map {
  border: none !important;
  width: 300px !important;
}

.example-overview .ol-overviewmap-box {
  border: 2px solid blue;
}

.example-overview,
.example-overview.ol-uncollapsible {
  bottom: auto !important;
  left: auto !important;
  right: 0 !important;
  top: 0 !important;
}

.example-overview:not(.ol-collapsed) button {
  bottom: auto !important;
  left: auto !important;
  right: 1px !important;
  top: 1px !important;
}

.example-fullscreen {
  top: 5em;
  right: 0.5em;
}

.fullscreen:-webkit-full-screen {
  height: 100%;
  margin: 0;
}

.fullscreen:-ms-fullscreen {
  height: 100%;
}

.fullscreen:fullscreen {
  height: 100%;
}

.fullscreen {
  margin-bottom: 10px;
  width: 100%;
  height: 400px;
}

.example-spinner {
  position: absolute;
  top: 40%;
  left: 40%;
  width: 20%;
  height: 20%;
  z-index: 1000;
}

.example-spinner>img {
  width: 100%;
}

